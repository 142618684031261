import { LookerEmbedSDK } from '@looker/embed-sdk';
import { LOOKER_EMBED_HOST } from '@webfx/marketingcloud-web/src/config/constants';
import { useActiveSite, useNotifications } from '@webfx/web-hooks';
import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import styles from './Looker.module.css';

const API_URL = process.env.FX_APP_API ? process.env.FX_APP_API : process.env.POI_APP_API;

/**
 * A React component to embed Looker dashboards into your application.
 * @param {object} props - The props object.
 * @param {number} props.dashboardId - The ID of the Looker dashboard to embed.
 * @param {string} props.divId - The ID of the div element where the dashboard should be embedded.
 * @param {Array<object>} [props.params] - An array of parameters to be passed to the Looker dashboard.
 * @param {Object} [props.lookerParams] - Additional parameters for configuring the Looker dashboard. https://cloud.google.com/looker/docs/themes-for-embedded-dashboards-and-explores#theme
 * @param {Object} [props.lookerFilters] - Filters to apply to the Looker dashboard.
 * @returns {JSX.Element} A React component to embed Looker dashboards.
 * @example
 * // Example usage:
 * const dashboardParams = [{ name: 'siteId', value: siteId, Date: "24+month", MROI_Channel: "", Form_Name: "" }];
 * <Looker divId="leads-events" dashboardId={112} params={dashboardParams} lookerParams={{ _theme: '{"show_title":false}' }} />;
 */
function Looker({ dashboardId, divId, params, lookerParams, lookerFilters }) {
  const { toast } = useNotifications();
  const { siteId } = useActiveSite();
  const dashboardParams = params || [{ name: 'siteId', value: siteId }];
  const [dashboardEmbedded, setDashboardEmbedded] = useState(false);
  const dashboardRef = useRef(false);

  useEffect(() => {
    if (dashboardRef.current) {
      return;
    }
    dashboardRef.current = true;
    makeDashboard();
  }, [siteId, toast]);

  const makeDashboard = () => {
    LookerEmbedSDK.init(LOOKER_EMBED_HOST, {
      url: `${API_URL}/looker-embed-auth`,
      params: dashboardParams,
      headers: [{ name: 'Authorization', value: localStorage.getItem('accessToken') }],
    });

    const dashboard = LookerEmbedSDK.createDashboardWithId(dashboardId);
    if (!isEmpty(lookerFilters)) {
      dashboard.withFilters(lookerFilters);
    }
    dashboard
      .appendTo(document.getElementById(divId))
      .withParams(lookerParams)
      .build()
      .connect()
      .then(() => {
        setDashboardEmbedded(true);
      })
      .catch((error) => {
        console.error(
          'An unexpected error occurred, dashboardEmbedded:' + dashboardEmbedded,
          error
        );
      });
  };

  return (
    <>
      <div id={divId} data-fx-name={divId + 'Dashboard'} className={styles.lookerDashboard}></div>
    </>
  );
}

export default Looker;
